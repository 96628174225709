// import pagePositionNav from '../../plugins/pagePositionNav';
// pagePositionNav.init();
// class Hoge {

//   aaaa;
//   b: string;

//   constructor() {
//     this.aaaa = document.querySelectorAll(".js-scrollNav-section")
//     this.b = "3"
//   }

//   init() {
//       console.log(this.b)
//   }
// }

// let ccc = new Hoge;
// ccc.init();

// type Coin = 10 | 50 | 100 | 500;
// type Bill = 1000;
// type Money = Coin | Bill;

// function insertMoney (money: Money) { 

//   console.log(money);
// }

// insertMoney(1000);