import { has } from "lodash";
import Vue from "vue/dist/vue.min.js";
export const beforeEnter = (el) => (el.style.height = "0");
export const enter = (el) => (el.style.height = el.scrollHeight + "px");
export const beforeLeave = (el) => (el.style.height = el.scrollHeight + "px");
export const leave = (el) => (el.style.height = "0");
const targetRootAttribute = "#knowing-list";
const targetRootAttributeChronology = "#chronology-list";
const targetRootAttributeLiterature = "#literature-list";

const listWrapAttribute = ".knowing-article-list";
const listWrapAttributeChronology = ".l-chronology_result_box";
const listWrapAttributeChronologyWrap = ".l-chronology_timeline_box";
const listWrapAttributeLiterature = ".l-bibliography_result_list_box";

const localStoragechronologyIndex = "chronologyIndex";

const targetRoot = document.querySelector(targetRootAttribute);
const targetRootChronology = document.querySelector(
    targetRootAttributeChronology
);
const targetRootLiterature = document.querySelector(
    targetRootAttributeLiterature
);

const sortAsc = (a, b) => a.localeCompare(b, "ja");
const sortDesc = (b, a) => a.localeCompare(b, "ja");

//右クリック禁止
document.oncontextmenu = function () {
    return false;
};

document
    .querySelectorAll(".js-back-button")
    .forEach((el) => el.addEventListener("click", () => history.back()));

//知る・用語集
if (targetRoot != null) {
    // 新着情報
    new Vue({
        el: targetRootAttribute,
        data: {
            limit: 6,
            increment: 6,
            displayList: [],
            isOpen: false,
        },
        watch: {
            displayList(val, old) {
                this.removeSortingList();
                this.addSortingList(val.slice(0, this.limit));
            },
            limit(n) {
                this.removeSortingList();
                this.addSortingList(this.displayList.slice(0, n));
            },
        },
        methods: {
            sortAsc,
            sortDesc,
            toggleSort() {
                this.isOpen = !this.isOpen;
            },
            getSortingList() {
                return Array.from(
                    document.querySelectorAll(
                        `${listWrapAttribute} a, ${listWrapAttributeChronology} a`
                    )
                );
            },
            addSortingList(sortedList) {
                sortedList.forEach((e) => {
                    document
                        .querySelector(listWrapAttribute)
                        .insertAdjacentElement("beforeend", e);
                });
            },
            removeSortingList() {
                document
                    .querySelectorAll(`${listWrapAttribute} a`)
                    .forEach((e) => e.parentNode.removeChild(e));
            },
            sortCategory(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return b.dataset.category.localeCompare(
                            a.dataset.category,
                            "ja"
                        );
                    } else {
                        return a.dataset.category.localeCompare(
                            b.dataset.category,
                            "ja"
                        );
                    }
                });
            },
            sortCategoryByAsc() {
                const list = this.displayList;
                const sortedList = this.sortCategory(list, "asc");

                this.displayList = sortedList;
            },
            sortCategoryByDesc() {
                const list = this.displayList;
                const sortedList = this.sortCategory(list);

                this.displayList = sortedList;
            },

            sortArea(list, mode = "desc") {
                return list.sort((a, b) => {
                    let asc_a = a.dataset.area
                        .split(",")
                        .sort(this.sortAsc)
                        .shift();
                    let desc_a = a.dataset.area
                        .split(",")
                        .sort(this.sortDesc)
                        .shift();
                    let asc_b = b.dataset.area
                        .split(",")
                        .sort(this.sortAsc)
                        .shift();
                    let desc_b = b.dataset.area
                        .split(",")
                        .sort(this.sortDesc)
                        .shift();

                    if (mode == "desc") {
                        return desc_b.localeCompare(desc_a, "ja");
                    } else {
                        return asc_a.localeCompare(asc_b, "ja");
                    }
                });
            },
            sortAreaByAsc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list, "asc");

                this.displayList = sortedList;
            },
            sortAreaByDesc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list);

                this.displayList = sortedList;
            },
            viewMore() {
                this.limit = this.limit + this.increment;
            },
            beforeEnter,
            enter,
            beforeLeave,
            leave,
        },
        created() {
            this.displayList = this.getSortingList();
        },
    });
}

//調べる・年表
if (targetRootChronology != null) {
    new Vue({
        el: targetRootAttributeChronology,
        data: {
            limit: 6,
            increment: 6,
            displayList: [],
            isOpen: false,
        },
        watch: {
            displayList(val, old) {
                this.removeSortingList();
                this.addSortingList(val.slice(0, this.limit));
            },
            limit(n) {
                this.removeSortingList();
                this.addSortingList(this.displayList.slice(0, n));
            },
        },
        methods: {
            sortAsc,
            sortDesc,
            toggleSort() {
                this.isOpen = !this.isOpen;
            },
            getSortingList() {
                return Array.from(
                    document.querySelectorAll(
                        `${listWrapAttribute} a, ${listWrapAttributeChronology}`
                    )
                );
            },
            addSortingList(sortedList) {
                sortedList.forEach((e) => {
                    document
                        .querySelector(listWrapAttributeChronologyWrap)
                        .insertAdjacentElement("beforeend", e);
                });
            },
            removeSortingList() {
                document
                    .querySelectorAll(`${listWrapAttributeChronology}`)
                    .forEach((e) => e.parentNode.removeChild(e));
            },
            sortYear(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return this.sortDesc(a.dataset.year, b.dataset.year);
                    } else {
                        return this.sortAsc(a.dataset.year, b.dataset.year);
                    }
                });
            },
            sortYearByAsc() {
                const list = this.displayList;
                const sortedList = this.sortYear(list, "asc");

                this.displayList = sortedList;
            },
            sortYearByDesc() {
                const list = this.displayList;
                const sortedList = this.sortYear(list);

                this.displayList = sortedList;
            },
            sortArea(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return this.sortDesc(a.dataset.area, b.dataset.area);
                    } else {
                        return this.sortAsc(a.dataset.area, b.dataset.area);
                    }
                });
            },
            sortAreaByAsc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list, "asc");
                this.displayList = sortedList;
            },
            sortAreaByDesc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list);

                this.displayList = sortedList;
            },
            sortType(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return this.sortDesc(a.dataset.type, b.dataset.type);
                    } else {
                        return this.sortAsc(a.dataset.type, b.dataset.type);
                    }
                });
            },
            sortTypeByAsc() {
                const list = this.displayList;
                const sortedList = this.sortType(list, "asc");
                this.displayList = sortedList;
            },
            sortTypeByDesc() {
                const list = this.displayList;
                const sortedList = this.sortType(list);

                this.displayList = sortedList;
            },
            viewMore() {
                this.limit = this.limit + this.increment;
                const limitTime = new Date().getTime() + 1000 * 60 * 60;
                localStorage.setItem(
                    localStoragechronologyIndex,
                    JSON.stringify({ count: this.limit, time: limitTime })
                );
            },
            beforeEnter,
            enter,
            beforeLeave,
            leave,
        },
        created() {
            this.displayList = this.getSortingList();
            this.sortYearByDesc();
        },
        mounted() {
            const hash = location.hash.slice(1);
            const target = document.getElementById(hash);

            if (target) {
                const localStorageIndex = JSON.parse(
                    localStorage.getItem(localStoragechronologyIndex)
                );

                this.limit =
                    (localStorageIndex && localStorageIndex.count) ||
                    this.increment;
            }
        },
    });
}

//調べる・文献
if (targetRootLiterature != null) {
    new Vue({
        el: targetRootAttributeLiterature,
        data: {
            limit: 6,
            increment: 6,
            displayList: [],
            isOpen: false,
        },
        watch: {
            displayList(val, old) {
                this.removeSortingList();
                this.addSortingList(val.slice(0, this.limit));
            },
            limit(n) {
                this.removeSortingList();
                this.addSortingList(this.displayList.slice(0, n));
            },
        },
        methods: {
            toggleSort() {
                this.isOpen = !this.isOpen;
            },
            getSortingList() {
                return Array.from(
                    document.querySelectorAll(
                        `${listWrapAttributeLiterature} a`
                    )
                );
            },
            addSortingList(sortedList) {
                sortedList.forEach((e) => {
                    document
                        .querySelector(listWrapAttributeLiterature)
                        .insertAdjacentElement("beforeend", e);
                });
            },
            removeSortingList() {
                document
                    .querySelectorAll(`${listWrapAttributeLiterature} a`)
                    .forEach((e) => e.parentNode.removeChild(e));
            },
            sortYear(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return a.dataset.year.localeCompare(
                            b.dataset.year,
                            "ja"
                        );
                    } else {
                        return b.dataset.year.localeCompare(
                            a.dataset.year,
                            "ja"
                        );
                    }
                });
            },
            sortYearByAsc() {
                const list = this.displayList;
                const sortedList = this.sortYear(list, "asc");

                this.displayList = sortedList;
            },
            sortYearByDesc() {
                const list = this.displayList;
                const sortedList = this.sortYear(list);

                this.displayList = sortedList;
            },
            sortArea(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return b.dataset.area.localeCompare(
                            a.dataset.area,
                            "ja"
                        );
                    } else {
                        return a.dataset.area.localeCompare(
                            b.dataset.area,
                            "ja"
                        );
                    }
                });
            },
            sortAreaByAsc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list, "asc");
                this.displayList = sortedList;
            },
            sortAreaByDesc() {
                const list = this.displayList;
                const sortedList = this.sortArea(list);

                this.displayList = sortedList;
            },
            // sortType(list, mode = "desc") {
            //     return list.sort((a, b) => {
            //         let array_a = [];
            //         let array_b = [];

            //         if (mode != "desc") {
            //             array_a = a.dataset.type
            //                 .split(",")
            //                 .sort((s, t) => s.localeCompare(t));
            //             array_b = b.dataset.type
            //                 .split(",")
            //                 .sort((s, t) => s.localeCompare(t));
            //         } else {
            //             array_a = a.dataset.type
            //                 .split(",")
            //                 .sort((t, s) => s.localeCompare(t));
            //             array_b = b.dataset.type
            //                 .split(",")
            //                 .sort((t, s) => s.localeCompare(t));
            //         }

            //         return array_a[0].localeCompare(array_b[0]);
            //     });
            // },
            sortType(list, mode = "desc") {
                return list.sort((a, b) => {
                    let array_a = a.dataset.type.toUpperCase();  // 大文字に変換してソート
                    let array_b = b.dataset.type.toUpperCase();  // 大文字に変換してソート
            
                    // もし降順の場合は、比較結果を反転
                    if (mode === "desc") {
                        return array_b.localeCompare(array_a);
                    }
            
                    return array_a.localeCompare(array_b);
                });
            },            
            sortTypeByAsc() {
                const list = this.displayList;
                const sortedList = this.sortType(list, "asc");
                this.displayList = sortedList;
            },
            sortTypeByDesc() {
                const list = this.displayList;
                const sortedList = this.sortType(list);

                this.displayList = sortedList;
            },
            sortContent(list, mode = "desc") {
                return list.sort((a, b) => {
                    if (mode == "desc") {
                        return a.dataset.content.localeCompare(
                            b.dataset.content,
                            "ja"
                        );
                    } else {
                        return b.dataset.content.localeCompare(
                            a.dataset.content,
                            "ja"
                        );
                    }
                });
            },
            sortContentByAsc() {
                const list = this.displayList;
                const sortedList = this.sortContent(list, "asc");

                this.displayList = sortedList;
            },
            sortContentByDesc() {
                const list = this.displayList;
                const sortedList = this.sortContent(list);

                this.displayList = sortedList;
            },
            viewMore() {
                this.limit = this.limit + this.increment;
            },
            beforeEnter,
            enter,
            beforeLeave,
            leave,
        },
        created() {
            this.displayList = this.getSortingList();
            this.sortYearByAsc();
        },
    });
}
