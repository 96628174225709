// import loader from '../../plugins/loader.js';
// loader.init();

//上のコメントを外すとローディングが有効になります。



//その後HTMLに以下を追記（どこでもOK）
//<div class="js-loader">
//  <div class="js-loader-progress">
//    <div class="js-loader-progress-bar"></div>
//    <div class="js-loader-progress-number"></div>
//  </div>
//</div>